const notifierRoutes = [
    {
        path : '/notifier',
        component : () => import('@/pages/restaurant/notifier.vue'),
        name : 'Notifier Screen',
        meta : {
            key : 'SM_KITCHEN_NOTIFIER_LOGIN',
            authRequired : true,
        },
    },
    
];

export default notifierRoutes;