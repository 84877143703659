import { useAuthStore } from '@/stores/auth';
import { useUserPermissionStore } from '@/stores/userPermissionStore';

export default function useGlobalFunctions() {
   
    function isAuthorized(menu_key){
        // console.log(menu_key,'menu_key');
        if(useAuthStore().user != null && useAuthStore().user.user_type == 1){
            return true;
        }else{
            if(useAuthStore().user != null && !_.isUndefined(useAuthStore().user)){

                var flag = false;

                let menus = useAuthStore().menus;
                let permissions = useAuthStore().user.role.menus;
                let permissions_ids = _.map(permissions, 'menu_id');

                if(!_.isArray(menu_key)){
                    menu_key = [menu_key];
                }

                menu_key.forEach( (key) => {

                    let menu = _.find(menus, (item) => item.menu_key == key  );

                    if( !_.isUndefined(menu) && permissions_ids.includes(menu.id)){
                        flag = true;
                    }

                })
                return flag;

            }else{

                location.href='/';

            }
        }

 
    }   

    function checkUserPermissions(permissions){    
        
        
        if(permissions.length > 0) {
            
            let data = [];
            
            permissions.forEach( (item) => {
    
                let permission = _.toUpper(item);
                
                if(isAuthorized(permission)){
                    
                    data.push({
                        permission : permission,
                        value : true,
                    });
                    
                }else{
                    
                    data.push({
                        permission : _.toUpper(permission),
                        value : false,
                    });
    
                }
    
            })

            useUserPermissionStore().setPermissions(data);

        }else{
            
            useUserPermissionStore().resetPermissions();

        }
        
    }

    function hasPermission(permission){
        
        permission = _.toUpper(permission);

        let permissions = useUserPermissionStore().getPermissions;
        
        let result = _.find(permissions,{
            permission: permission,
            value: true,
        });
        
        if(!_.isUndefined(result)){
            return true;
        }else{
            return false;
        }

    }
    
    async function signOut(){

        try{

            // is_processing.value = true;

            let response = await useAuthStore().logout();

            if(response) {
                // is_processing.value = false;

                router.push('/');

            }

        }catch(error) {
            //server_messages.type = "error";
            // server_messages.messages = error;
            // is_processing.value = false;
            //console.log(error.error);
            if (401 === error.response.status) {
                router.push('/'); 
            } 
        };
        
    }

    function getRoundedValue(value,decimal_points = 2){
        return _.round(value,decimal_points);
    }

    return {
        isAuthorized,
        checkUserPermissions,
        hasPermission,
        signOut,
        getRoundedValue,
    }


}


