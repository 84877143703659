const otherRoutes = [
    {
        path : '/forbidden',
        component : () => import('@/pages/other/ForbiddenErrorPage.vue'),
        name : '401'
    },
    {
        path : '/:notFound(.*)',
        component : () => import('@/pages/other/NotFoundErrorPage.vue'),
        name : '404'
    },
];

export default otherRoutes;