const authRoutes = [
    {
        path : '/',
        component : () =>import('@/pages/login.vue'),
        name : 'login',
        meta : {
            authRequired : false,
        },
    },
    {
        path : '/user-login',
        component : () =>import('@/pages/user_login.vue'),
        name : 'user-login',
        meta : {
            authRequired : false,
        },
    },
    
    
];

export default authRoutes;