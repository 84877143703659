<template>

    <!-- <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
            <component :is="Component" /> 
        </Transition>
    </router-view> -->

    <router-view></router-view>

</template>

<script setup>

  import { useSettingStore } from '@/stores/settingStore';
  const settingStore = useSettingStore();

  onMounted( () => {
      settingStore.getSettings();
  });

</script>

<style scoped>
    /* .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    } */
</style>